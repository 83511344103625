@import './reset';

$bgColor: #000000;
$grey: #dedede;
$black90: #0e0e0e;
$pink: #f3109e;
$blue: #181c84;
$lightBlue: #5b60ff;
$purple: #9020c4;
$green: #3a9916;
$borderRd1: 8px;
// Define a function to generate gradients
@mixin gradient-bg($angle, $colors...) {
  background: linear-gradient($angle, $colors...);
}

:root {
  --font-inverted: #000000;
}

.site-layout {
  min-height: 100vh;

  &--content {
    height: 100%;
    padding: 24px;
    background: var(--font-inverted);
  }
}
.ant-input {
  background: rgba($grey, 0.1) !important;
  border-color: rgba($grey, 0.05) !important;
  color: $grey;
  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: $grey;

    box-shadow: inset 0 0 20px 20px $bgColor;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: $black90 !important;
  border-color: rgba($grey, 0.1);
}
.ant-input-affix-wrapper {
  .anticon {
    color: $grey;
  }
}
.ant-checkbox + span {
  color: $grey;
}
.ant-card {
  background: $bgColor;
  .ant-card-head {
    border-color: rgba($grey, 0.1);
  }
}
.ant-card-bordered {
  border: 1px solid rgba($grey, 0.1);
}
.ant-statistic-title,
.ant-statistic-content,
h4.ant-typography,
.ant-card-head-title {
  color: $grey;
}

.ant-picker-range {
  width: 100%;
  background: $black90;
  border-color: rgba($grey, 0.1);
  color: $grey;
  .anticon-swap-right,
  .anticon-calendar {
    color: $grey;
  }
}
.ant-picker-panel-container {
  background: $black90;
  .ant-picker-header,
  .ant-picker-cell-in-view,
  .ant-picker-content th {
    color: $grey;
  }
}
.ant-picker-header,
.ant-picker-panel {
  border-color: rgba($grey, 0.2);
}
.ant-picker-cell,
.ant-picker-input > input {
  color: rgba($grey, 0.5);
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: rgba($grey, 0.2);
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after,
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  border-color: rgba($grey, 0.5);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-range-hover,
.ant-picker-range .ant-picker-active-bar {
  background: $pink;
}
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  background: rgba($grey, 0.3);
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $pink;
  border-right-width: 1px;
}
.link-btn {
  color: rgba($pink, 0.8);
  font-size: 1.3em;
  &:hover {
    color: $pink;
  }
}

.btn-icon {
  color: rgba($grey, 0.3);
  font-size: 1.3em;
}
.btn-primary {
  @include gradient-bg(-90deg, $pink, $blue);
  border: none;
  width: 100%;
  opacity: 0.9;
  &:hover {
    @include gradient-bg(-90deg, $pink, $blue);
    opacity: 1;
  }
}
.site-sidebar {
  .user {
    margin: 16px;
    color: var(--font-inverted);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.ant-radio-button-wrapper {
  background: $black90;
  border: 1px solid rgba($grey, 0.1);
  color: rgba($grey, 0.5);
  &:first-child {
    border: 1px solid rgba($grey, 0.2);
  }
  &:not(:first-child)::before {
    background: rgba($grey, 0.2);
  }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:hover {
  border: none;
  color: rgba($grey, 0.9) !important;
  @include gradient-bg(-90deg, $pink, $blue);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.sub-text {
  color: $pink;
}
.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background: $bgColor;
  flex-direction: column;
  justify-content: center;

  .login-form {
    width: 400px;
    margin: 0px;
  }

  .login-form-button {
    width: 100%;
  }

  .login-form-forgot {
    display: block;
    text-align: center;
  }
}

.loading-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    font-size: 40px;
    color: #1890ff;
  }
}

.sound-player-column {
  vertical-align: middle;
}

.search-input {
  width: 250px;
  margin-bottom: 14px;
  margin-right: 14px;
}

.search-operator {
  margin-bottom: 14px;

  & > span {
    margin-right: 14px;
  }
}

.truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ant-table {
  background: $bgColor;
  color: rgba($grey, 0.5);
  .ant-table-thead {
    & > tr {
      & > th {
        background: $black90;
        color: rgba($grey, 1);
        border: 1px solid $bgColor;
      }
    }
  }
  .ant-table-tbody {
    & > tr {
      & > td {
        border-bottom: 1px solid rgba($grey, 0.1);
      }
    }
    & > tr.ant-table-row:hover {
      & > td {
        background: rgba($grey, 0.1);
        color: rgba(#ffffff, 0.7);
      }
    }
  }
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.1);
  color: $grey;
  &:hover {
    @include gradient-bg(300deg, $pink, $blue);
    border: none;
    color: $grey;
    a {
      &:hover {
        color: $grey;
      }
    }
  }
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  background: rgba($grey, 0.1);
  &:hover {
    @include gradient-bg(300deg, $pink, $blue);
    border: none;
    color: $grey;
    a {
      &:hover {
        color: $grey;
      }
    }
  }
}
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: $grey;
  opacity: 1;
}
.ant-pagination-disabled {
  .ant-pagination-item-link {
    background: rgba($grey, 0.1);
    border: 1px solid rgba($grey, 0.1);
    color: rgba(#ffffff, 0.3);
  }
}
.ant-pagination-item {
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.1);
  color: $grey;
  &:hover {
    @include gradient-bg(300deg, $pink, $blue);
    border: none;
    color: $grey;
  }
  a {
    color: $grey;
    &:hover {
      color: $grey;
    }
  }
}
.ant-pagination-item-active {
  background: $pink;
  @include gradient-bg(300deg, $pink, $blue);
  border: none;
  color: $grey;
  a {
    color: $grey;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.1);
  color: rgba(#ffffff, 0.3);
  &:hover {
    border: 1px solid rgba($grey, 0.1);
    color: $grey;
  }
}
.ant-select-arrow {
  color: $grey;
}

.ant-select-dropdown {
  background: $black90;
  border: 1px solid rgba($grey, 0.1);
  color: rgba($grey, 0.5);
  .ant-select-item-option-content,
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: rgba($grey, 0.5);
  }
  .ant-select-item-option-selected,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-dropdown .ant-select-item-option-selected,
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba($grey, 0.1);
  }
}

.ant-layout-sider {
  background: $black90;
  overflow: auto;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  .ant-menu-item,
  .ant-menu-submenu {
    span.ant-menu-item-icon {
      padding-top: 6px 0 !important;
      padding-bottom: 6px 0 !important;
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.ant-menu.ant-menu-dark {
  background: transparent;
}
.ant-menu-item,
.ant-menu-submenu {
  border-bottom: 1px solid rgba($bgColor, 1);
  .ant-menu-title-content {
    color: rgba($grey, 0.3);
  }
  &:hover {
    background: $bgColor;
  }
}
.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background: rgba($grey, 0.5);
}

.ant-layout-sider-trigger {
  background: rgba($grey, 0.05);
  text-align: right;
  padding-right: 35px;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: $bgColor;
}

.card-content-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .card-wrap {
    max-width: 600px;
    text-align: center;
    padding: 40px;
  }
}

.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85px;
  margin-bottom: 20px;
}
.dashboard-wrap {
  color: rgba($grey, 0.3);
  .top-content {
    width: 100%;
    .ant-row {
      .ant-col {
        margin-bottom: 10px;
        &:last-child {
          text-align: right;
          & > .ant-select {
            text-align: left;
          }
        }
        .ant-statistic {
          @include gradient-bg(-90deg, $pink, $blue);
          display: flex;
          border-radius: $borderRd1;
          justify-content: space-between;
          align-items: center;
          padding: 2px 15px;
        }
      }
    }
  }
  .overview-content {
    color: rgba($grey, 0.3);
    margin: 25px 0;
  }
}
.overall-chart {
  margin: 25px 0;
  .stats-card-wrap {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    justify-content: space-between;
    .ant-card {
      width: 100%;
      background: rgba($grey, 0.1);
      border-radius: $borderRd1;
      &:first-child {
        background: rgba($blue, 0.6);
      }
      &:nth-child(2) {
        background: rgba($purple, 0.6);
      }
      &:last-child {
        background: rgba($pink, 0.6);
      }
      .ant-statistic-content-value-int {
        font-size: 1.4em;
        font-weight: 500;
      }
    }
  }
}
.features-time-wrap {
  .ant-card {
    width: 100%;
    background: rgba($grey, 0.05);
    border-radius: $borderRd1;
    .ant-statistic-content-value-int {
      font-size: 1.4em;
      font-weight: 500;
      color: rgba($lightBlue, 1);
    }
    .ant-statistic-content-suffix {
      color: rgba($lightBlue, 1);
    }
  }
}

.list-card {
  width: 100%;
  background: rgba($grey, 0.05);
  border-radius: $borderRd1;
}

.master-search-wrap {
  .ant-row {
    margin-bottom: 25px;
  }
}

.master-data-overview {
  .stats-card-wrap {
    margin-bottom: 20px;
    .ant-card {
      margin-bottom: 20px;
      background-color: $black90;
    }
  }
}

.value-range {
  .ant-statistic-content {
    display: none;
  }
  .ant-slider-rail {
    @include gradient-bg(-90deg, $pink, $blue);
  }
  .ant-slider-dot {
    background-color: $purple;
  }
  .ant-slider-mark-text {
    color: $grey;
  }
}

.user-overview {
  margin-bottom: 20px;
  h2 {
    color: #ffffff;
    margin: 0 !important;
  }
  .sub-text-grey {
    color: rgba($grey, 0.5);
  }
  .ant-progress-inner,
  .ant-progress-bg {
    height: 15px !important;
  }
  .ant-progress-inner {
    background-color: rgba($grey, 0.1);
    margin-top: 10px;
  }
  .ant-progress-text {
    color: rgba($grey, 0.8);
  }
  .points-wrap {
    background: rgba($grey, 0.05);
    border-radius: $borderRd1;
    margin-top: 10px;
    .ant-card-body {
      padding: 0px 20px !important;
    }
  }
  .ant-statistic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $borderRd1;
    .ant-statistic-content-value-int,
    .ant-statistic-content-value-decimal,
    .ant-statistic-content-suffix {
      font-size: 0.75em;
    }
  }
}

.user-filter-data {
  display: flex;
  .tab-btn {
    width: 150px;
  }
}

.user-activity {
  margin-top: 20px;
  .ant-card {
    margin-bottom: 20px;
  }
}
